import Colors from './colors'

let styles = {
    mainLayout: {
        width: '100vw', 
        display: 'flex', 
        flexDirection: 'row',
    },

    navIcon: {
        color: 'whiteSmoke',
        width: '40px',
        fontSize: '2.5em',
        marginBottom: '20px',
    },

    appContainer: {
        display: 'flex',
        flexDirection: 'row',
        minHeight: '100vh', 
        height: '100%',
        width: '100vw', 
        backgroundColor: "WhiteSmoke"
    },

    navContainer: {
        display: 'flex', 
        width: '18.5%', 
        height: '100%',
        flexDirection: 'column',
        paddingLeft: '10px',
        paddingRight: '10px',
        borderRightColor: Colors.themeColor,
        borderRightWidth: "2px",
        borderRightStyle: "solid",
    },

    navItem: {
        color: Colors.themeColor,
        paddingLeft: '5px', 
        paddingTop: '20px', 
        paddingBottom: '20px', 
        borderBottomWidth: "2px", 
        borderBottomStyle: 'solid', 
        borderBottomColor: Colors.themeColor,
    },

    ContentContainer: {
        width: '100%',
        backgroundColor: 'whitesmoke',
    }
}


export default styles;