import React, { useState } from 'react'
import config from '../lib/config'
import Tbutton from './Tbutton'
import Card from './Card'
import { TextField } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import Nav from './Nav'
import { useAuth0 } from '@auth0/auth0-react'

function AddNewPerson(props) {

    const [name, setName] = useState('')
    const { isAuthenticated, getAccessTokenSilently } = useAuth0();

    const Navigate = useNavigate();

    const handleSubmit = async () => {
        let formData = {
            "firstName": name,
        }
        let response = await fetch(config.baseUrl + "/contacts/addContact", {
            method: 'post',
            credentials: 'include',
            headers: {
                "Authorization": "Bearer " + (isAuthenticated ? await getAccessTokenSilently() : ""),
            },
            body: JSON.stringify(formData)})
        let responseJson = await response.json();
        if (responseJson.success === true) {
            setName(''); // Clear the input field
            props.successFunction()
            Navigate('/onePerson/' + responseJson.status);
        }
    }
  return (
    <Card style={props.style} title={"Add a new contact"}>
        <TextField 
        variant={"standard"}
        label={"Name"}
        value={name}
        onChange={(e) => setName(e.target.value)}
        />
        <Tbutton style={{marginTop: '10px', marginLeft: '10px'}} title={"Submit"} onClick={handleSubmit} />
    </Card>
    )
}

export default AddNewPerson