let Colors = {
    themeColor: '#2b2b2b',
    feedColor: '#FF4858',
    listsColor: '#1B7F79',
    contactsColor: '#00CCC0',
    calendarColor: '#72F2EB',
    assetsColor: '#747F7F',
    tensileRed: '#F45D48',
    tensileYellow: '#FFB703',
    tensileGreen: '#078080',
}

export default Colors;