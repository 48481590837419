import React from 'react'
import Colors from '../lib/colors'
import { useEffect, useState } from 'react'
import styles from '../lib/Styles'
import config from '../lib/config'
import { BrowserRouter as Router, Switch, Route, Link, useParams} from 'react-router-dom'
import LeftNav from './Nav'
import { colors, Paper } from '@mui/material'
import ContentContainer from './ContentContainer'
import Card from './Card'
import Nav from './Nav'
import { attributesCacheState } from '../lib/state'
import { useRecoilState } from 'recoil'
import { render } from '@testing-library/react'
import { useAuth0 } from '@auth0/auth0-react'

const AttributesPreview = (props) => {

    const [attributesCache, setAttributesCache] = useRecoilState(attributesCacheState);
    const [attributes, setAttributes] = useState(typeof attributesCache[props.parentUuid] !== 'undefined' ? attributesCache[props.parentUuid] : []);

    const { isAuthenticated, getAccessTokenSilently } = useAuth0();

    const getAttributes= async () => {
        const attributesData = await fetch(config.baseUrl + `/properties/getProperties?parentUuid=${props.parentUuid}`, 
                                          {credentials: 'include',
                                          headers: {
                                            "Authorization": "Bearer " + (isAuthenticated ? await getAccessTokenSilently() : ""),
                                          },});
        const attributesJson = await attributesData.json();
        if (attributesJson.success === true) {
            setAttributes(attributesJson.status);
            setAttributesCache((attributes) => {
                let _attributes = {...attributes};
                _attributes[props.parentUuid] = attributesJson.status;
                return(_attributes);
            });
        }
    }
    useEffect(() => {
        getAttributes();
    }, [])

    const renderAttributesPreview = () => {
        return attributes.map((attribute, index) => {
            return(
                <div>
                    <span style={{fontWeight: 'bold'}}>{attribute.title}: </span>
                    <span>{attribute.propertyValue}</span>
                </div>)
        })
    }

    return (
        <div style={{...styles.mainLayout}}>
                <div style={{display: 'flex', flexWrap: 'wrap', flexDirection: 'column', justifyContent: 'flex-start'}}>
                    {attributes.length > 0 ? renderAttributesPreview() : null}

                </div>
        </div>
    )
}

export default AttributesPreview