import React from 'react'
import Colors from '../lib/colors'
import { useEffect, useState } from 'react'
import styles from '../lib/Styles'
import config from '../lib/config'
import { BrowserRouter as Router, Switch, Route, Link, useParams} from 'react-router-dom'
import LeftNav from '../components/Nav'
import { colors, Paper } from '@mui/material'
import ContentContainer from '../components/ContentContainer'
import Card from '../components/Card'
import Nav from '../components/Nav'
import { thingsCacheState } from '../lib/state'
import { attributesCacheState } from '../lib/state'
import { useRecoilState } from 'recoil'
import _ from 'lodash';
import PropertiesPreview from '../components/AttributesPreview'
import { useNavigate } from 'react-router-dom'
import AddNewThing from '../components/AddNewThing'
import { useAuth0 } from '@auth0/auth0-react'

const Things = (props) => {

    const [things, setThings] = useRecoilState(thingsCacheState);
    const [attributes, setAttributes] = useRecoilState(attributesCacheState);

    const { isAuthenticated, getAccessTokenSilently } = useAuth0();

    let Navigate = useNavigate();

    const getAttributes = async (thingUuid) => {
        const propertiesData = await fetch(config.baseUrl + '/properties/getProperties?parentUuid=' + thingUuid, 
                                          {credentials: 'include',
                                          headers: {
                                            "Authorization": "Bearer " + (isAuthenticated ? await getAccessTokenSilently() : ""),
                                          },});
        const propertiesJson = await propertiesData.json();
        if (propertiesJson.success === true) {
            console.log('Got properties:', propertiesJson.status);
            let _attributes = {...attributes};
            _attributes[thingUuid] = propertiesJson.status;
            console.log('Setting attributes:', Object.keys(_attributes))
            setAttributes(_attributes);
        }
    }

    const getThings = async () => {
        const thingsData = await fetch(config.baseUrl + `/things/getThings`, 
                                      {credentials: 'include',
                                      headers: {
                                        "Authorization": "Bearer " + (isAuthenticated ? await getAccessTokenSilently() : ""),
                                      },});
        const thingsJson = await thingsData.json();
        let _thingsJson = _.cloneDeep(thingsJson);
        if (thingsJson.success === true) {
            //sort it by title
            _thingsJson.status = _.sortBy(thingsJson.status, ['title']);
            setThings(_thingsJson.status);
        }
    }
    useEffect(() => {
        getThings();
    }, [])

    const renderThings = () => {
        return(
            things.map((thing, index) => {
                return(
                    <Card key={thing.uuid} onClick={() => Navigate(`/oneThing/${thing.uuid}`)} style={{marginRight: '10px', marginBottom: '10px', maxWidth: "295px"}} title={thing.title} key={index}>
                        <PropertiesPreview parentUuid={thing.uuid} />
                    </Card>
                )
            })
        )
    }

    return (
        <div style={{...styles.mainLayout}}>
            <Nav />
            <ContentContainer title={'Things'}>
                <div style={{display: 'flex', flexWrap: 'wrap', flexDirection: 'row', justifyContent: 'flex-start'}}>
                    <AddNewThing style={{marginRight: '10px', marginBottom: '10px', width: "295px"}} successFunction={getThings} />
                    {things.length > 0 ? renderThings() : ""}
                </div>
            </ContentContainer>
        </div>
    )
}

export default Things