import React from 'react'
import Colors from '../../lib/colors'
import { useEffect, useState } from 'react'
import styles from '../../lib/Styles'
import config from '../../lib/config'
import { BrowserRouter as Router, Switch, Route, Link, useParams} from 'react-router-dom'
import LeftNav from '../../components/Nav'
import { colors, Paper } from '@mui/material'
import ContentContainer from '../../components/ContentContainer'
import Card from '../../components/Card'
import Nav from '../../components/Nav'
import { useAuth0 } from '@auth0/auth0-react'
import Tbutton from '../../components/Tbutton'

const PublicLanding = (props) => {

    const [publicLanding, setPublicLanding] = useState([]);
    const { loginWithPopup } = useAuth0();

    

    return (
        <div style={{alignContent: 'center',
                    backgroundColor: Colors.tensileYellow, 
                    alignItems: 'center', 
                    flexDirection: 'column', 
                    position: 'fixed', 
                    display: 'flex', 
                    height: '100%', 
                    width: '100%'}}>
            <Card style={{marginTop: '50px', width: '80%', backgroundColor: 'whitesmoke'}}>
                <div>
                    <h1>Welcome to Tensile</h1>
                    <p>Sign in to get started</p>
                    <Tbutton onClick={() => {loginWithPopup({returnTo: "/"})}} title="Login" />
                </div>
            </Card>
        </div>
    )
}

export default PublicLanding