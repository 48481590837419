import React from 'react'
import Colors from '../lib/colors'
import styles from '../lib/Styles'
import { useEffect, useState } from 'react'
import LogOutButton from './LogOutButton'
import { useNavigate } from 'react-router-dom'
import Logo from './Logo'
import { colors } from '@mui/material'
import 'material-icons/iconfont/material-icons.css';
import { useRecoilState, useResetRecoilState } from 'recoil'
import config from '../lib/config'
import { authDescriptionState, listsState, listsCacheState } from '../lib/state'
import { useAuth0 } from '@auth0/auth0-react'
import { loggedInState } from '../lib/state'

const Nav = (props) => {

    let navigate = useNavigate();
    let resetAuth = useResetRecoilState(authDescriptionState);
    let restLists = useResetRecoilState(listsState);
    let resetListsCache = useResetRecoilState(listsCacheState);
    const [loggedIn, setLoggedIn] = useRecoilState(loggedInState);

    const { logout } = useAuth0();

    useEffect(() => {
    }, [])

    const fetchLogout = async () => {
        await fetch(`${config.baseUrl}/auth/logout`, {credentials: 'include'}).then(() => {
            resetAuth();
            restLists();
            resetListsCache();
            //refresh the page
            window.location.reload();
        });

    }
    
    return (
        <div style={{backgroundColor: Colors.tensileYellow,
                    width: '60px',
                    display: 'flex',
                    flexDirection: 'column',
                    padding: '20px',
                    alignItems: 'center',

        }}>

            <span onClick={() => navigate("/")} class="material-icons-outlined" style={styles.navIcon}>feed</span>  
            <span onClick={() => navigate("/allLists")} class="material-icons" style={styles.navIcon}>list</span>  
            <span onClick={() => navigate("/things")} class="material-icons" style={styles.navIcon}>widgets</span>
            <span onClick={() => navigate("/people")} class="material-icons" style={styles.navIcon}>contacts</span>  
            <span onClick={() => navigate("/calendar")} class="material-icons" style={styles.navIcon}>calendar_month</span>  
            <span onClick={() => navigate("/places")} class="material-icons" style={styles.navIcon}>map</span>
            
            <span onClick={() => {setLoggedIn(false); fetchLogout(); logout({ returnTo: "/" })}} class="material-icons" style={styles.navIcon}>logout</span>
        </div>
    )
}

export default Nav