import React from 'react'
import Login from '../pages/login'
import config from '../lib/config'
import { useEffect, useState } from 'react'
import { loggedInState } from '../lib/state'
import { gotAuthState } from '../lib/state'
import { authDescriptionState } from '../lib/state'
import { useRecoilState } from 'recoil'
import { BrowserRouter as Router, Switch, Route, Routes, Link, useParams} from 'react-router-dom'
import Things from '../pages/Things';
import Landing from '../pages/Landing';
import Calendar from '../pages/Calendar';
import People from '../pages/People';
import AllLists from '../pages/AllLists';
import styles from '../lib/Styles'
import Places from '../pages/Places'
import OneList from '../pages/OneList'
import ListItem from '../pages/ListItem'
import OneThing from '../pages/OneThing'
import OnePerson from '../pages/OnePerson'
import OneAction from '../pages/OneAction'
import { useAuth0 } from "@auth0/auth0-react";
import { auth0TokenState } from '../lib/state'
import PublicLanding from '../pages/public/PublicLanding'
import PublicList from '../pages/public/PublicList'

const AppContainer = (props) => {

    var [loggedIn, setLoggedIn] = useRecoilState(loggedInState);
    var [gotAuth, setGotAuth] = useRecoilState(gotAuthState);
    var [authDescription, setAuthDescription] = useRecoilState(authDescriptionState);
    const { user, isAuthenticated, isLoading, getAccessTokenSilently } = useAuth0();
    const { loginWithRedirect, loginWithPopup, login } = useAuth0();
    const [auth0Token, setAuth0Token] = useRecoilState(auth0TokenState);

    //if they aren't authenticated, then we need to authenticate them

    useEffect(() => {
        (async () => {
          try {
            const token = await getAccessTokenSilently();
            console.log("\n\nToken: ", token, "\n\n");
          } catch (e) {
            console.error(e);
            if (e.error == "login_required") {
                loginWithPopup();
            }
          }
        })();
      }, [getAccessTokenSilently]);

    const _AuthRouter = () => {
        return(
        <Router>
            <Routes>
                <Route path="/" element={<Landing />} />
                <Route path="/allLists" element={<AllLists />} />
                <Route path="/things" element={<Things />} />
                <Route path="/calendar" element={<Calendar />} />
                <Route path="/people" element={<People />} />
                <Route path="/places" element={<Places />} />
                <Route path="/oneList" element={<OneList />}>
                    <Route path=":uuid" element={<OneList />} />
                </Route>
                <Route path="/listItem" element={<ListItem />}>
                    <Route path=":uuid" element={<ListItem />} />
                </Route>
                <Route path="/oneThing" element={<OneThing />}>
                    <Route path=":uuid" element={<OneThing />} />
                </Route>
                <Route path="/onePerson" element={<OnePerson />}>
                    <Route path=":uuid" element={<OnePerson />} />
                </Route>
                <Route path="/oneAction" element={<OneAction />}>
                    <Route path=":uuid" element={<OneAction />} />
                </Route>
            </Routes>
        </Router>
        )
    }
    const _PublicRouter = () => {
        return(
        <Router>
            <Routes>
                <Route path="/" element={<PublicLanding />} />
                <Route path="/oneList" element={<PublicList />}>
                    <Route path=":uuid" element={<PublicList />} />
                </Route>
            </Routes>
        </Router>
        )
    }

    if (isLoading == false) {
        return(
            <div style={styles.appContainer}>
                {isAuthenticated ? _AuthRouter() : _PublicRouter()}
            </div>
        )
    } else {
        return(
            <div>
                Loading...
            </div>
        )
    }
}

export default AppContainer