import React from 'react'
import { useEffect, useState } from 'react'
import config from '../lib/config'
import Tbutton from './Tbutton';
import { useRecoilState } from 'recoil';
import { newListTitleState } from '../lib/state';
import { GetLists } from '../lib/utils';
import { useNavigate } from 'react-router-dom';
import Nav from './Nav';
import { TextField } from '@mui/material';
import { useAuth0 } from '@auth0/auth0-react';

function AddNewList(props) {

    const [newListTitle, setNewListTitle] = useRecoilState(newListTitleState);
    const { isAuthenticated, getAccessTokenSilently } = useAuth0();

    const Navigate = useNavigate();

    const handleSubmit = async () => {
        console.log("I'm submooooooooting...");
        const data = {
            title: newListTitle,
            description: "",
            listItems: [],
        }
        let response = await fetch(config.baseUrl + "/toDoLists/createToDoList", {
            method: 'post',
            credentials: 'include',
            headers: {
                "Authorization": "Bearer " + (isAuthenticated ? await getAccessTokenSilently() : ""),
              },
            body: JSON.stringify(data)});
        let responseJson = await response.json();
        console.log(responseJson);
        if (responseJson.success === true) {
            GetLists();
            Navigate('/oneList/' + responseJson.status);
        }
    }

    return (
        <div>
            <h2>Create a new list</h2>
            <input variant='standard' type="text" onChange={(e) => setNewListTitle(e.target.value)} />
                <Tbutton style={{marginTop: '10px', marginLeft: '10px'}} title={"Submit"} onClick={handleSubmit} />
        </div>
    )
}

export default AddNewList;