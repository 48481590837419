import React from 'react'
import Colors from '../lib/colors'
import { useEffect, useState } from 'react'
import styles from '../lib/Styles'
import config from '../lib/config'
import { BrowserRouter as Router, Switch, Route, Link, useParams} from 'react-router-dom'
import LeftNav from '../components/Nav'
import { colors, Paper } from '@mui/material'
import ContentContainer from '../components/ContentContainer'
import Card from '../components/Card'
import Nav from '../components/Nav'


const Places = (props) => {
    return(
        <div style={{...styles.mainLayout}}>
        <Nav />
        <ContentContainer title={"Places"}>
            <Card>
                <span>Some things.</span>
            </Card>
        </ContentContainer>
        </div>
    )
}

export default Places