import React from 'react'
import Colors from '../lib/colors'
import { useEffect, useState } from 'react'
import styles from '../lib/Styles'
import config from '../lib/config'
import { BrowserRouter as Router, Switch, Route, Link, useParams} from 'react-router-dom'
import LeftNav from '../components/Nav'
import { colors, Paper } from '@mui/material'
import ContentContainer from '../components/ContentContainer'
import Card from '../components/Card'
import Nav from '../components/Nav'
import Tbutton from './Tbutton'
import { getAgeString } from '../lib/utils'
import TextField from '@mui/material/TextField'
import UserAction from './UserAction'
import { useAuth0 } from '@auth0/auth0-react'

const Comments = (props) => {

    const [comments, setcomments] = useState([]);
    const [newComment, setNewComment] = useState('');

    const { isAuthenticated, getAccessTokenSilently } = useAuth0();

    const getcomments = async () => {
        console.log("Getting comments for parentUuid:", props.parentUuid);
        const commentsData = await fetch(config.baseUrl + `/comments/getComments?parentUuid=${props.parentUuid}`, 
                                        {credentials: 'include',
                                        headers: {
                                            "Authorization": "Bearer " + (isAuthenticated ? await getAccessTokenSilently() : ""),
                                        },});
        const commentsJson = await commentsData.json();
        if (commentsJson.success === true) {
            setcomments(commentsJson.status);
        }
    }

    const handleSubmitComment = async () => {
        console.log("Submitting comment:", newComment)
        let formData = new FormData();
        formData.append("commentText", newComment)
        formData.append("parentUuid", props.parentUuid)
        let response = await fetch(config.baseUrl + "/comments/addComment", {
            method: 'post',
            body: formData,
            headers: {
                "Authorization": "Bearer " + await getAccessTokenSilently(),
            },
            credentials: 'include'
        })
        let responseJson = await response.json();
        if (responseJson.success === true) {
            if (typeof props.successFunction === 'function') {
                props.successFunction()
            }
            setNewComment('');
            getcomments();
        } else {
        }
    }

    useEffect(() => {
        getcomments();
    }, [props.parentUuid])


    const commentBox = () => {
        return (
            <div>
            <TextField 
            variant={"standard"}
            label={"Add Comment"}
            style={{
                marginTop: '30px',
                width: '100%',
                marginBottom: '10px',}} 
                value={newComment}
                onChange={(e) => setNewComment(e.target.value)}
                />
            <Tbutton onClick={() => handleSubmitComment()} style={{marginLeft: '0px'}} title={"Add Comment"}  />
            </div>
        )
    
    }

    const rendercomments = () => {
        console.log("Rendering comments:", comments)
        return comments.map((comment, index) => {
            let ageString = getAgeString(comment.creationDate);

            return(
                <div style={{display: 'flex', flexDirection: 'column'}}>
                    <UserAction
                        userProfilePicture={comment.userProfilePicture}
                        displayName={comment.displayName}
                        ageString={ageString}
                        payload={comment.commentText}
                    />
                </div>
            )
        })
    }


    return (
        <Card style={{width: '46%', minWidth: '400px'}} title="Comments">

            <div style={{marginBottom: '10px'}}>
                {comments.length > 0 ? rendercomments() : null}
                {commentBox()}
            </div>
        </Card>
    )
}

export default Comments