import React from 'react'
import { useEffect, useState } from 'react'
import config from '../lib/config'
import { useNavigate } from 'react-router-dom'
import { TextField } from '@mui/material'
import Card from './Card'
import Tbutton from './Tbutton'
import { useAuth0 } from '@auth0/auth0-react'

function AddNewThing(props) {
    const [newThingTitle, setNewThingTitle] = useState('')
    const { isAuthenticated, getAccessTokenSilently } = useAuth0();

    let Navigate = useNavigate();

    const addThing = async () => {
        let body = {title: newThingTitle}
        const createThingData = await fetch(config.baseUrl + '/things/addThing', {
            method: 'POST',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
                "Authorization": "Bearer " + (isAuthenticated ? await getAccessTokenSilently() : ""),
            },
            body: JSON.stringify(body)
        });
        const createThingJson = await createThingData.json();
        if (createThingJson.success === true) {
            Navigate('/oneThing/' + createThingJson.status);
            props.successFunction();
        }
    }

  return (
    <Card style={props.style} title={"Add something new"}>
        <TextField
        variant={"standard"}
        label={"Title"}
        value={newThingTitle}
        onChange={(e) => setNewThingTitle(e.target.value)}
        />
        <Tbutton style={{marginTop: '10px', marginLeft: '10px'}} title={"Submit"} onClick={addThing} />
    </Card>
    )
}

export default AddNewThing