import React, { useEffect } from 'react'
import AutoComplete from '@mui/material/Autocomplete'
import TextField from '@mui/material/TextField'
import { useState } from 'react'
import Tbutton from './Tbutton'
import Colors from '../lib/colors'
import config from '../lib/config'
import { useAuth0 } from '@auth0/auth0-react'

function NewAttribute(props) {
    const [newTitle, setNewTitle] = useState('')
    const [newValue, setNewValue] = useState('')
    const [newTitleCandidates, setNewTitleCandidates] = useState([])
    const [newValueCandidates, setNewValueCandidates] = useState([])
    const [updateRequested, setUpdateRequested] = useState(false)

    const { isAuthenticated, getAccessTokenSilently } = useAuth0();

    const getAttributeCandidtates = async () => {
        const attributeCandidatesData = await fetch(config.baseUrl + `/properties/getPropertyTitles`, 
                                                   {credentials: 'include',
                                                   headers: {
                                                    "Authorization": "Bearer " + (isAuthenticated ? await getAccessTokenSilently() : ""),
                                                   },});
        const attributeCandidatesJson = await attributeCandidatesData.json();
        setNewTitleCandidates(attributeCandidatesJson.status);
    }

    const getAttributeValueCandidates = async () => {
        const attributeValueCandidates = await fetch(config.baseUrl + `/properties/getPropertyValues?title=${newTitle}`, 
                                                    {credentials: 'include',
                                                    headers: {
                                                        "Authorization": "Bearer " + (isAuthenticated ? await getAccessTokenSilently() : ""),
                                                    },});
        const attributeValueCandidatesJson = await attributeValueCandidates.json();
        setNewValueCandidates(attributeValueCandidatesJson.status);
    }

    const submitFunction = async () => {
        const submitData = await fetch(config.baseUrl + '/properties/addProperty?title=' + newTitle + '&value=' + newValue + "&parentUuid=" + props.parentUuid, 
                                      {credentials: 'include',
                                      headers: {
                                        "Authorization": "Bearer " + (isAuthenticated ? await getAccessTokenSilently() : ""),
                                      },});
        const submitJson = await submitData.json();
        if (submitJson.success == true) {
            setNewTitle('');
            setNewValue('');
            props.onSubmit();
        }
    }

    const armUpdate = (t, v) => {
        if (((newTitle !== '')) && ((newValue !== ''))) {
            setUpdateRequested(true);
        } else {
            setUpdateRequested(false);
        }
    }

    useEffect(() => {
        getAttributeCandidtates();
    }, []);

    useEffect(() => {
        armUpdate();
    }, [newTitle, newValue]);

    return (
        <div style={{width: '100%', display: "flex", flexDirection: "column", alignItems: "center", width: '100%', backgroundColor: ''}}>
            <div style={{width: '100%'}}>
            <h4>New Attribute</h4>
            </div>
            <div style={{width: '100%', display: "flex", flexDirection: "row", alignItems: "center", width: '100%', backgroundColor: ''}}>
                <div style={{marginRight: '10px', width: '40%', backgroundColor: ""}}>
                    <AutoComplete
                        freeSolo={true}
                        options={newTitleCandidates}
                        style={{width: '100%'}}
                        value={newTitle}
                        onInputChange={(e, v) => {
                            setNewTitle(v);
                            armUpdate(v, "");
                        }}
                        renderInput={(params) => <TextField {...params} label={"New Title"} variant="standard" />}
                    />
                </div>
                <div style={{marginRight: '10px', width: '55%'}}>
                    <div style={{widht: '80%'}}>
                        <AutoComplete
                            freeSolo={true}
                            options={newValueCandidates}
                            style={{}}
                            onFocus={() => getAttributeValueCandidates()}
                            value={newValue}
                            onInputChange={(e, v) => {
                                setNewValue(v);
                                armUpdate("", v);
                            }}
                            renderInput={(params) => <TextField {...params} label={"New Attribute Value"} variant="standard" />}
                        />
                    </div>
                    
                </div>
                
            </div>
            <div style={{width: '100%'}}>
                        <Tbutton style={{
                            backgroundColor: updateRequested ? Colors.tensileYellow : "lightgray", 
                            marginTop: '10px'}} 
                            title={"Add"}
                            onClick={() => submitFunction()}>Add</Tbutton>
            </div>
        </div>
    )
}

export default NewAttribute