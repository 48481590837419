import React from 'react'
import AutoComplete from '@mui/material/Autocomplete'
import TextField from '@mui/material/TextField'
import { useState } from 'react'
import Tbutton from './Tbutton'
import Colors from '../lib/colors'
import config from '../lib/config'
import { useAuth0 } from '@auth0/auth0-react'

function MutableAttribute(props) {
    const [autoCompleteValue, setAutoCompleteValue] = useState(props.value)
    const [updateRequested, setUpdateRequested] = useState(false)

    const { isAuthenticated, getAccessTokenSilently } = useAuth0();

    const updateValue = async () => {
        console.log("Updating property with uuid: ", props.attribute.propertyUuid);
        const updateData = await fetch(config.baseUrl + '/properties/updateProperty?title=' + props.attribute.title + '&value=' + autoCompleteValue + "&parentUuid=" + props.attribute.parentUuid + "&propertyUuid=" + props.attribute.propertyUuid, 
                                      {credentials: 'include',
                                      headers: {
                                        "Authorization": "Bearer " + (isAuthenticated ? await getAccessTokenSilently() : ""),
                                      },});
        const updateJson = await updateData.json();
        console.log("UpdateJson: ", updateJson);
        if (updateJson.success == true) {
            props.onSubmit();
            setUpdateRequested(false);
        }
    }

    return (
        <div id={"EA Mutable"} style={{width: '55%', display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center"}}>
            <div style={{marginRight: '10px', width: '80%'}}>
                <AutoComplete
                    freeSolo={true}
                    options={props.options}
                    style={{width: '100%'}}
                    value={autoCompleteValue}
                    onInputChange={(e, v) => {
                        setAutoCompleteValue(v); 
                        setUpdateRequested(true); 
                        if (v === props.value) {
                            setUpdateRequested(false)
                        }; 
                        if (v === "") {
                            console.log("The thing was an empty string")
                            setUpdateRequested(false);
                        }
                    }}
                    renderInput={(params) => <TextField value={"really big butts."} {...params} style={{...props.style}} label={props.title} variant="standard" />}
                />
            </div>
            <div>
                <Tbutton style={{
                        backgroundColor: updateRequested ? Colors.tensileYellow : "lightgray", 
                        marginTop: '10px',
                        width: 'auto',
                        }} 
                        title={"Update"}
                        onClick={() => {updateValue(); console.log("Updating...", autoCompleteValue, " Should be: ", props.value)}}>Update</Tbutton>
            </div>
        </div>
    )
}

export default MutableAttribute