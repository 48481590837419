import React from 'react'
import Colors from '../lib/colors'
import { useEffect, useState } from 'react'
import styles from '../lib/Styles'
import config from '../lib/config'
import { BrowserRouter as Router, Switch, Route, Link, useParams, Navigate} from 'react-router-dom'
import LeftNav from '../components/Nav'
import { colors, Paper } from '@mui/material'
import ContentContainer from '../components/ContentContainer'
import Card from '../components/Card'
import Nav from '../components/Nav'
import { useNavigate } from 'react-router-dom'
import { listsState } from '../lib/state'
import { listsCacheState } from '../lib/state'
import { itemsCacheState } from '../lib/state'
import { useRecoilState } from 'recoil'
import AddNewList from '../components/AddNewList'
import { useAuth0 } from '@auth0/auth0-react'


const AllLists = (props) => {

    let navigate = useNavigate();

    const [lists, setLists] = useRecoilState(listsState);
    const [listsCache, setListsCache] = useRecoilState(listsCacheState);
    const [itemsCache, setItemsCache] = useRecoilState(itemsCacheState);

    const { isAuthenticated, getAccessTokenSilently } = useAuth0();

    useEffect(() => {
        getLists()
    }, [])

    const getLists = async () => {
        //fetch the lists.  The API endpoint is: /toDoLists/all
        console.log("I'm getting the lists...");
        console.log("isAuthenticated: ", isAuthenticated)
        console.log("Bearer " + (isAuthenticated ? await getAccessTokenSilently() : ""));
        const listsData = await fetch(`${config.baseUrl}/toDoLists/all`, 
                                     {credentials: 'include',
                                     headers: {
                                        "Authorization": "Bearer " + (isAuthenticated ? await getAccessTokenSilently() : ""),
                                      },}
                                    )
        console.log("AllLists ", listsData);
        const listsJson = await listsData.json()
        
        if (listsJson.success === true) {
            console.log("AllLists ", listsJson)
            setLists(listsJson.status)
            let _listsCache = {...listsCache};
            let _itemsCache = {...itemsCache};
            listsJson.status.map((list) => {
                _listsCache[list.listUuid] = [list];
                list.listItems.map((item) => {
                    _itemsCache[item.itemUuid] = item;
                })
            })
            setListsCache(_listsCache);
            setItemsCache(_itemsCache);
        }
        
    }

    const renderItemPreview = (listItems) => {
        return listItems.map((item, index) => {
            return(
                <div key={index}>
                    <span>{item.itemTitle}</span>
                </div>
            )
        })
    }

    const renderLists = () => {
        return lists.map((list, index) => {
            return(
                //conclick navigation to the list
                <div onClick={() => navigate(`/oneList/${list.listUuid}`)} key={index}>
                    <Card style={{marginBottom: '10px', marginRight: '10px', width: '295px'}}>
                        <h2 style={{marginBottom: '0px'}}>{list.title}</h2>
                        <div style={{fontSize: '0.8em', marginBottom: '10px'}}>By: {list.displayName}</div>
                        {renderItemPreview(list.listItems.slice(0,5))}
                    </Card>
                </div>
            )
        })
    }

    return(
        <div style={{...styles.mainLayout}}>
        <Nav />
        <ContentContainer title={"Lists"}>
            <div style={{display: 'flex', flexWrap: 'wrap', flexDirection: 'row', justifyContent: 'flex-start'}}>
                <Card key={"addNewList"} style={{width: '295px', marginBottom: '10px', marginRight: '10px'}}>
                    <AddNewList />
                </Card>
                {lists.length > 0 ? renderLists() : null}
            </div>
        </ContentContainer>
        </div>
    )
}

export default AllLists