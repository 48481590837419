import React from 'react'
import ContentHeader from './ContentHeader'
import styles from '../lib/Styles'

function ContentContainer(props) {
  return (
            <div style={{paddingLeft: '15px', paddingBottom: '300px', paddingRight: '15px',...styles.ContentContainer}}>
                <ContentHeader title={props.title} />
                {props.children}
            </div>
        )
    }

export default ContentContainer