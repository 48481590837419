import React from 'react'
import Colors from '../lib/colors'
import { useEffect, useState } from 'react'
import styles from '../lib/Styles'
import config from '../lib/config'
import { BrowserRouter as Router, Switch, Route, Link, useParams} from 'react-router-dom'
import LeftNav from '../components/Nav'
import { colors, Paper } from '@mui/material'
import ContentContainer from '../components/ContentContainer'
import Card from '../components/Card'
import Nav from '../components/Nav'
import { itemsCacheState } from '../lib/state'
import { useRecoilState } from 'recoil'
import Comments from '../components/Comments'
import Tags from '../components/Tags'
import Actions from '../components/Actions'
import Attributes from '../components/Attributes'
import { useAuth0 } from '@auth0/auth0-react'

const ListItem = (props) => {

    const [listItem, setListItem] = useState([]);
    const [itemsCache, setItemsCache] = useRecoilState(itemsCacheState)

    const { isAuthenticated, getAccessTokenSilently } = useAuth0();

    let {uuid} = useParams();
    console.log('Got itemUuid:', uuid)

    const getListItem = async () => {
        const listItemData = await fetch(config.baseUrl + `/toDoLists/getListItem?itemUuid=${uuid}`, 
                                        {credentials: 'include',
                                        headers: {
                                            "Authorization": "Bearer " + (isAuthenticated ? await getAccessTokenSilently() : ""),
                                        },});
        const listItemJson = await listItemData.json();
        setListItem(listItemJson.status);
        if (listItemJson.success == true) {
            let _itemsCache = {...itemsCache};
            _itemsCache[listItemJson.status[0].itemUuid] = listItemJson.status[0];
            setItemsCache(_itemsCache);
        }
    }
    useEffect(() => {
        getListItem();
    }, [])

    const renderListItem = () => {
        console.log('Rendering list item:', listItem);
        let item = listItem[0];
        let creationDate = new Date(item.creationDate);
        //make a string of the date that looks nice

        return (
            <div style={{width: '100%'}}>
                <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', flexWrap: 'wrap'}}>
                    <Card title={item.itemTitle} style={{marginBottom: '10px', marginRight: '10px', width: '46%', minWidth: '400px'}}>
                        <div style={{display: 'flex', flexDirection: 'column'}}>
                            <div>Added by: {item.displayName}</div>
                            <div>Creation date: {creationDate.getMonth()+1}/{creationDate.getDate()}/{creationDate.getFullYear()}</div>
                            <div>Complete: {item.complete ? "Yes" : "No"} </div>
                            <Attributes parentUuid={item.itemUuid} />
                        </div>
                    </Card>
                    <Tags parentUuid={uuid} />
                    <Comments parentUuid={item.itemUuid} />
                    <Actions parentUuid={item.itemUuid} />  
                </div>    
            </div>
        )
    }

    return (
        <div style={{...styles.mainLayout}}>
            <Nav />
            <ContentContainer title={''}>
                <div style={{display: 'flex', flexWrap: 'wrap', flexDirection: 'row', justifyContent: 'flex-start'}}>
                    {listItem.length > 0 ? renderListItem() : null}
                </div>
                
            </ContentContainer>
        </div>
    )
}

export default ListItem