import React from 'react'
import Colors from '../lib/colors'
import { useEffect, useState } from 'react'
import styles from '../lib/Styles'
import config from '../lib/config'
import { BrowserRouter as Router, Switch, Route, Link, useParams} from 'react-router-dom'
import LeftNav from '../components/Nav'
import { Autocomplete, colors, Paper } from '@mui/material'
import ContentContainer from '../components/ContentContainer'
import Card from '../components/Card'
import Nav from '../components/Nav'
import Tbutton from './Tbutton'
import { getAgeString } from '../lib/utils'
import Useraction from './UserAction'
import AutoComplete from '@mui/material/Autocomplete'
import TextField from '@mui/material/TextField'
import { useNavigate } from 'react-router-dom'
import { useAuth0 } from '@auth0/auth0-react'

const Actions = (props) => {

    const [actions, setActions] = useState([]);
    const [actionTypes, setActionTypes] = useState([])
    const [moreHidden, setMoreHidden] = useState(true)
    const [actionCandidates, setActionCandidates] = useState([]);
    const [newActionCandidate, setNewActionCandidate] = useState({})
    const [attachHidden, setAttachHidden] = useState(true)
    const [actionsArmed, setActionsArmed] = useState(true)
    const { isAuthenticated, getAccessTokenSilently } = useAuth0();

    let Navigate = useNavigate();

    const takeAction = async (actionTypeUuid) => {
        if (actionsArmed === true) {
            setActionsArmed(false);
            setTimeout(() => {
                setActionsArmed(true);
            }, 500);
            console.log(config.baseUrl + '/actions/takeAction?actionTypeUuid=' + actionTypeUuid + '&parentUuid=' + props.parentUuid);
            const takeActionData = await fetch(config.baseUrl + '/actions/takeAction?actionTypeUuid=' + actionTypeUuid + '&parentUuid=' + props.parentUuid, 
                                              {credentials: 'include',
                                              headers: {
                                                "Authorization": "Bearer " + (isAuthenticated ? await getAccessTokenSilently() : ""),
                                              },}
                                              );
            const takeActionJson = await takeActionData.json();
            console.log('Take Action Json:', takeActionJson)
            if (takeActionJson.success === true) {
                getActions();
            }
        }
    }

    const getActionCandidates = async () => {
        const actionCandidatesData = await fetch(config.baseUrl + `/actions/getActionTypes`, 
                                                {credentials: 'include',
                                                headers: {
                                                    "Authorization": "Bearer " + (isAuthenticated ? await getAccessTokenSilently() : ""),
                                                  },}
                                                );
        const actionCandidatesJson = await actionCandidatesData.json();
        if (actionCandidatesJson.success === true) {
            setActionCandidates(actionCandidatesJson.status);
        }
        console.log('Got Action Candidates json:', actionCandidatesJson);
    }

    const getActionTypes = async () => {
        const actionTypesData = await fetch(config.baseUrl + `/actions/getAttachments?parentUuid=${props.parentUuid}`,
                                           {credentials: 'include',
                                            headers: {
                                                "Authorization": "Bearer " + (isAuthenticated ? await getAccessTokenSilently() : ""),
                                            },}
                                            );
        const actionTypesJson = await actionTypesData.json();
        if (actionTypesJson.success === true) {
            //sort them by actionTitle
            let _actionTypes = actionTypesJson.status;
            _actionTypes.sort((a, b) => {
                if (a.actionTitle.toLowerCase() < b.actionTitle.toLowerCase()) {
                    return -1;
                }
                if (a.actionTitle.toLowerCase() > b.actionTitle.toLowerCase()) {
                    return 1;
                }
                return 0;
            })
            setActionTypes(_actionTypes);
            console.log('Got Action types json:', _actionTypes);
        }}

    const getActions = async () => {
        const actionsData = await fetch(config.baseUrl + `/actions/getActions?parentUuid=${props.parentUuid}`, 
                                        {credentials: 'include',
                                        headers: {
                                            "Authorization": "Bearer " + (isAuthenticated ? await getAccessTokenSilently() : ""),
                                        },});
        const actionsJson = await actionsData.json();
        if (actionsJson.success === true) {
            setActions(actionsJson.status);
        }
        console.log('Got Actions json:', actionsJson);
    }

    useEffect(() => {
        getActionTypes();
        getActions();
        getActionCandidates();
    }, [])

    const renderActionTypes = () => {
        return actionTypes.map((action, index) => {
            return(
                <Tbutton style={{marginRight: '10px', marginBottom: '10px', backgroundColor: actionsArmed ? Colors.tensileYellow : "lightgray"}} title={action.actionTitle} key={index} onClick={() => {takeAction(action.attachmentTypeUuid)}} />
            )
        })
    }

    

    const renderMore = () => {
        if (actions.length > 10) {
            let _moreSlice = actions.slice(10, actions.length);
            return(
                _moreSlice.map((action, index) => {
                    return(
                        <div style={{display: moreHidden ? "none" : "flex", flexDirection: 'column'}}>
                            <Useraction onClick={() => Navigate(`/oneAction/${action.actionUuid}`)} userProfilePicture={action.imageUrl} displayName={action.displayName} ageString={getAgeString(action.actionDate)} payload={action.actionTitle} />
                        </div>
                    )
                })    
            )
        }
    }

    const moreButton = () => {
        return(
            <Tbutton style={{marginRight: '10px', marginBottom: '10px'}} title={moreHidden ? `${actions.length - 10} More` : "Less"} onClick={() => setMoreHidden(!moreHidden)} />
        )
    }

    const createActionType = async (title) => {
        const createActionTypeData = await fetch(config.baseUrl + '/actions/createActionType?title=' + title, 
                                                {credentials: 'include',
                                                headers: {
                                                    "Authorization": "Bearer " + (isAuthenticated ? await getAccessTokenSilently() : ""),
                                                },});
        const createActionTypeJson = await createActionTypeData.json();
        console.log('Create Action Type Json:', createActionTypeJson);
        if (createActionTypeJson.success == true) {
            return(createActionTypeJson.status)
        }
    } 

    const attachAction = async () => {
        console.log("Trying to attach action:", newActionCandidate)
        let actionTypeUuid = ""
        if (typeof newActionCandidate.actionTypeUuid === "undefined") {
            actionTypeUuid = await createActionType(newActionCandidate);
        } else {
            actionTypeUuid = newActionCandidate.actionTypeUuid;
        }
        const attachActionData = await fetch(config.baseUrl + '/actions/attachAction?parentUuid=' + props.parentUuid + '&actionTypeUuid=' + actionTypeUuid, 
                                            {credentials: 'include',
                                            headers: {
                                                "Authorization": "Bearer " + (isAuthenticated ? await getAccessTokenSilently() : ""),
                                            },});
        const attachActionJson = await attachActionData.json();
        console.log('Attach Action Json:', attachActionJson);
        if (attachActionJson.success == true) {
            getActionTypes();
            getActions();
            setNewActionCandidate({title: ""})
        }
    }

    const promptAttachment = () => {
        return(
            <div style={{display: attachHidden ? "none" : "flex", flexDirection: 'row' }}>
                <div style={{display: 'flex', marginBottom: '40px', marginRight: '30px', width: '100%'}}>
                    <AutoComplete
                        freeSolo={true}
                        options={[{title: ""},...actionCandidates]}
                        getOptionLabel={(option) => { return(typeof option.title !== "undefined" ? option.title : "")}}
                        style={{width: '100%'}}
                        value={newActionCandidate.title}
                        onChange={(e, v) => {typeof v.title !== "undefined" ? setNewActionCandidate(v) : setNewActionCandidate({title: ""})}}
                        onInputChange={(e, v) => {setNewActionCandidate(v)}}
                        renderInput={(params) => <TextField {...params} label="Add Action" variant="standard" />}
                    />
                </div>
                <div>
                    <Tbutton style={{marginTop: '10px'}} title={"Add"} onClick={() => {console.log("Add action clicked"); attachAction()}}>Add Action</Tbutton>
                </div>
            </div>
        )
    }

    const renderActions = () => {
        //show onlyt the first 15 actions
        let _actions = actions.slice(0, 10);
        let _moreActions = [];
        if (actions.length > 10) {
            _moreActions = actions.slice(10, actions.length);
        }
        return _actions.map((action, index) => {
            return(
                <div style={{display: 'flex', flexDirection: 'column'}}>
                    <Useraction onClick={() => Navigate(`/oneAction/${action.actionUuid}`)} userProfilePicture={action.imageUrl} displayName={action.displayName} ageString={getAgeString(action.actionDate)} payload={`${action.actionTitle}`} />
                </div>
            )
        })
    }

    return (
        <Card style={{width: '46%', minWidth: '400px', marginBottom: '10px'}} title="Actions">
                <div style={{marginBottom: '10px'}}>
                    <div style={{marginBottom: '30px'}}>
                        {actionTypes.length > 0 ? renderActionTypes() : null}
                        <Tbutton style={{backgroundColor: attachHidden ? Colors.tensileYellow : Colors.tensileRed}} title={attachHidden == true ? "New Action" : "Cancel"} onClick={() => setAttachHidden(!attachHidden)} />
                    </div>
                    {promptAttachment()}
                    {actions.length > 0 ? renderActions() : null}
                    {actions.length > 10 ? renderMore() : null}
                    {actions.length > 10 ? moreButton() : null}
                </div>
        </Card>
    )
}

export default Actions