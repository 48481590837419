import React from 'react'

function UserAction(props) {
  return (
    <div onClick={() => props.onClick()} style={{marginBottom: '10px', display: 'flex', flexDirection: 'row', justifyContent: 'flex-start'}}>
        <div style={{marginRight: '10px', display: 'flex'}}>
            <img src={props.userProfilePicture} style={{width: '40px', height: '40px', borderRadius: '50%'}} />
        </div>
        <div>
            <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
                <div style={{display: 'flex', fontWeight: 'bold'}}>
                    By: {props.displayName}
                </div>
                <div style={{marginLeft: '5px', fontStyle: 'italic'}}>
                    {props.ageString}
                </div>
            </div>
            <div>
                {props.payload}
            </div>
        </div>
    </div>
  )
}

export default UserAction