import React from 'react'
import Colors from '../../lib/colors'
import { useEffect, useState } from 'react'
import styles from '../../lib/Styles'
import config from '../../lib/config'
import { BrowserRouter as Router, Switch, Route, Link, useParams} from 'react-router-dom'
import LeftNav from '../../components/Nav'
import { colors, Paper } from '@mui/material'
import ContentContainer from '../../components/ContentContainer'
import Card from '../../components/Card'
import { useNavigate } from 'react-router-dom'
import Nav from '../../components/Nav'


const PublicList = (props) => {

    const [publicList, setPublicList] = useState([]);

    let { uuid } = useParams();

    let Navigate = useNavigate();

    const getPublicList = async () => {
        const publicListData = await fetch(config.baseUrl + `/toDoLists/getToDoList?listUuid=${uuid}`);
        const publicListJson = await publicListData.json();
        setPublicList(publicListJson.status);
        console.log('Got PublicList json:', publicListJson.status);
        if (publicListJson.status === "you are not authenticated") {
            //redirect back to the root
            Navigate("/");
        }
    }
    useEffect(() => {
        getPublicList();
    }, [])

    const renderPublicList = () => {
        console.log(publicList);
        return(
            <div>
            <div style={{marginBottom: '10px', fontStyle: 'italic'}}>By: {publicList[0].displayName}</div>
            {publicList[0].listItems.map((listItem, index) => {
                return(
                    <div>
                        {listItem.itemTitle}
                    </div>
                )
            })}
            </div>
        )
    }

    return (
        <div style={{alignContent: 'center',
                    backgroundColor: Colors.tensileYellow, 
                    alignItems: 'center', 
                    flexDirection: 'column', 
                    position: 'fixed', 
                    display: 'flex', 
                    height: '100%', 
                    width: '100%'}}>
            <Card title={publicList?.[0]?.title ?? ""} style={{marginTop: '50px', width: '80%', backgroundColor: 'whitesmoke'}}>
                <div>
                    {publicList?.[0]?.listItems.length > 0 ? renderPublicList() : null}
                </div>
            </Card>
        </div>
    )
}

export default PublicList