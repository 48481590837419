import React, { useEffect } from 'react'
import { tagsCacheState } from '../lib/state'
import { useRecoilState } from 'recoil'
import { useState } from 'react'
import config from '../lib/config'
import { useAuth0 } from '@auth0/auth0-react'

function TagsPreview(props) {
    const [tagsCache, setTagsCache] = useRecoilState(tagsCacheState);
    const [tags, setTags] = useState(() => {
        console.log("Trying to start witht his cache of tags:", tagsCache);
        console.log("Parent UUID:", props.parentUuid);
        console.log("This is what I have for tags right now: ", tagsCache[props.parentUuid]);
        if (tagsCache[props.parentUuid]) {
            return tagsCache[props.parentUuid];
        } else {
            return [];
        }
    });

    const { isAuthenticated, getAccessTokenSilently } = useAuth0();

    const getTags = async () => {
        const tagsData = await fetch(config.baseUrl + `/tags/getTags?parentUuid=${props.parentUuid}`, 
                                    {credentials: 'include',
                                    headers: {
                                        "Authorization": "Bearer " + (isAuthenticated ? await getAccessTokenSilently() : ""),
                                    },
                                });
        const tagsJson = await tagsData.json();
        if (tagsJson.success === true) {
            setTags(tagsJson.status);
            setTagsCache((tagsCache) => {
                let _tagsCache = {...tagsCache};
                _tagsCache[props.parentUuid] = tagsJson.status;
                return _tagsCache;
            });
        }
        console.log('Got Tags json:', tagsJson);
    }

    useEffect(() => {
        getTags();
    }, [])

    const renderTags = () => {
        return tags.map((tag, index) => {
            return (
                <div key={tag.uuid}>
                    {tag.title}
                </div>
            )
        })
    }

    return (
        <div>
            {renderTags()}
        </div>
    )
}

export default TagsPreview