import React from 'react'
import Colors from '../lib/colors'
import { useEffect, useState } from 'react'
import styles from '../lib/Styles'
import config from '../lib/config'
import { BrowserRouter as Router, Switch, Route, Link, useParams} from 'react-router-dom'
import LeftNav from '../components/Nav'
import { colors, Paper } from '@mui/material'
import ContentContainer from '../components/ContentContainer'
import Card from '../components/Card'
import Nav from '../components/Nav'
import AutoComplete from '@mui/material/Autocomplete'
import TextField from '@mui/material/TextField'
import Tbutton from './Tbutton'
import 'material-icons/iconfont/material-icons.css';
import { tagsCacheState } from '../lib/state'
import { useRecoilState } from 'recoil'
import { useAuth0 } from '@auth0/auth0-react'

const Tags = (props) => {

    const [tags, setTags] = useState([]);
    const [allTags, setAllTags] = useState([])
    const [newTag, setNewTag] = useState('');
    const [tagsCache, setTagsCache] = useRecoilState(tagsCacheState);

    const { isAuthenticated, getAccessTokenSilently } = useAuth0();

    const getTags = async () => {
        const tagsData = await fetch(config.baseUrl + `/tags/getTags?parentUuid=${props.parentUuid}`, 
                                    {credentials: 'include',
                                    headers: {
                                        "Authorization": "Bearer " + (isAuthenticated ? await getAccessTokenSilently() : ""),
                                    },});
        const tagsJson = await tagsData.json();
        if (tagsJson.success === true) {
            setTags(tagsJson.status);
            let _tagsCache = {...tagsCache};
            _tagsCache[props.parentUuid] = tagsJson.status;
        }
        console.log('Got Tags json:', tagsJson);
    }

    const addTag = async () => {
        let formData = new FormData();
        console.log("Adding tag:", newTag)
        formData.append("parentUuid", props.parentUuid)
        formData.append("title", newTag.title);
        
        let response = await fetch(config.baseUrl + "/tags/addTag", {
            method: 'post',
            body: formData,
            headers: {
                "Authorization": "Bearer " + (isAuthenticated ? await getAccessTokenSilently() : ""),
            },
            credentials: 'include'})
        let responseJson = await response.json();
        if (responseJson.success === true) {
            setNewTag("")
            getTags();
        }
    }

    const removeTag = async (tagUuid) => {
        let formData = new FormData();
        formData.append("tagUuid", tagUuid)
        let response = await fetch(config.baseUrl + "/tags/removeTag", {
            method: 'post',
            body: formData,
            headers: {
                "Authorization": "Bearer " + (isAuthenticated ? await getAccessTokenSilently() : ""),
            },
            credentials: 'include'})
        let responseJson = await response.json();
        if (responseJson.success === true) {
            getTags();
        }
    }

    const getAllTags = async () => {
        const tagsData = await fetch(config.baseUrl + `/tags/allTags`, 
                                    {credentials: 'include',
                                    headers: {
                                        "Authorization": "Bearer " + (isAuthenticated ? await getAccessTokenSilently() : ""),
                                    },});
        const tagsJson = await tagsData.json();
        if (tagsJson.success === true) {
            setAllTags(tagsJson.status.map((tag) => {
                return {title: tag.title}
            }));
        }
        console.log('Got AllTags json:', tagsJson);
    }

    useEffect(() => {
        getTags();
        getAllTags();
    }, [])

    const renderTags = () => {
        return tags.map((tag, index) => {
            return(
            <div style={{display: 'flex', flexDirection: 'row'}}>
                <div>
                    <span key={tag.tagUuid} onClick={() => removeTag(tag.tagUuid)} class="material-icons-outlined" style={{display: '', color: 'lightgray', marginRight: '10px'}}>remove_circle</span>  
                </div>
                <div>
                    {tag.title}
                </div>
            </div>
            )
        })
    }

    const newTagBox = () => {
        return (
            <div style={{marginTop: '40px'}}>
                <AutoComplete
                    freeSolo={true}
                    options={[{title: ""},...allTags]}
                    getOptionLabel={(option) => { return(typeof option.title !== "undefined" ? option.title : "")}}
                    style={{width: '100%'}}
                    value={newTag}
                    onInputChange={(e, v) => { setNewTag({title: v})}}
                    renderInput={(params) => <TextField {...params} label="Add Tag" variant="standard" />}
                />
                <Tbutton style={{marginTop: '10px'}} title={"Add Tag"} onClick={() => {addTag()}}>Add Tag</Tbutton>
            </div>
        )
    }  

    return (
        <Card style={{width: '46%', minWidth: '400px', marginBottom: '10px'}} title="Tags">
            <div style={{...props.style}}>
                <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'space-between'}}>
                    {tags.length > 0 ? renderTags() : null}
                </div>
                {newTagBox()}
            </div>
        </Card>
    )
}

export default Tags