import React from 'react'
import { useEffect, useState } from 'react'
import config from '../lib/config'
import Tbutton from './Tbutton';
import { useRecoilState } from 'recoil';
import { newListItemTitleState } from '../lib/state';
import { useAuth0 } from '@auth0/auth0-react';

function AddToList(props) {

    const [newListItemState, setNewListItemState] = useRecoilState(newListItemTitleState);
    const { isAuthenticated, getAccessTokenSilently } = useAuth0();

    const handleSubmit = async () => {
        let formData = new FormData();
        formData.append("listUuid", props.listUuid);
        formData.append("itemTitle", newListItemState);
        let response = await fetch(config.baseUrl + "/toDoLists/addListItem", {
            method: 'post',
            body: formData,
            headers: {
                "Authorization": "Bearer " + (isAuthenticated ? await getAccessTokenSilently() : ""),
            },
            credentials: 'include'})
        let responseJson = await response.json();
        if (responseJson.success === true) {
            props.successFunction()
        }
    }

    return (
        <div>
            <input type="text" onSubmit={() => console.log("submit")} onChange={(e) => setNewListItemState(e.target.value)} />
                <Tbutton style={{margin: '10px'}} title={"Submit"} onClick={handleSubmit} />
        </div>
    )
}

export default AddToList