import React from "react";

function Logo(props) {
  return (
    <div style={{...props.style}}>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      viewBox="0 0 224.57 224.57"
    >
      <path
        fill="none"
        fillOpacity="0.915"
        fillRule="evenodd"
        stroke="#fff"
        strokeDasharray="none"
        strokeLinecap="butt"
        strokeLinejoin="miter"
        strokeMiterlimit="4"
        strokeOpacity="1"
        strokeWidth="10.01"
        d="M.012 112.55l224.546-.53"
      ></path>
      <path
        fill="none"
        fillOpacity="0.915"
        fillRule="evenodd"
        stroke="#fff"
        strokeDasharray="none"
        strokeLinecap="butt"
        strokeLinejoin="miter"
        strokeMiterlimit="4"
        strokeOpacity="1"
        strokeWidth="10.01"
        d="M32.71 33.083l159.15 158.404"
      ></path>
      <path
        fill="none"
        fillOpacity="0.915"
        fillRule="evenodd"
        stroke="#fff"
        strokeDasharray="none"
        strokeLinecap="butt"
        strokeLinejoin="miter"
        strokeMiterlimit="4"
        strokeOpacity="1"
        strokeWidth="10.01"
        d="M14.921 56.377L209.65 168.193"
      ></path>
      <path
        fill="none"
        fillOpacity="0.915"
        fillRule="evenodd"
        stroke="#fff"
        strokeDasharray="none"
        strokeLinecap="butt"
        strokeLinejoin="miter"
        strokeMiterlimit="4"
        strokeOpacity="1"
        strokeWidth="10.01"
        d="M3.77 83.482L220.8 141.088"
      ></path>
      <path
        fill="none"
        fillOpacity="0.915"
        fillRule="evenodd"
        stroke="#fff"
        strokeDasharray="none"
        strokeLinecap="butt"
        strokeLinejoin="miter"
        strokeMiterlimit="4"
        strokeOpacity="1"
        strokeWidth="10.01"
        d="M55.92 15.186l112.73 194.198"
      ></path>
      <path
        fill="none"
        fillOpacity="0.915"
        fillRule="evenodd"
        stroke="#fff"
        strokeDasharray="none"
        strokeLinecap="butt"
        strokeLinejoin="miter"
        strokeMiterlimit="4"
        strokeOpacity="1"
        strokeWidth="10.01"
        d="M82.971 3.906L141.6 220.664"
      ></path>
      <path
        fill="none"
        fillOpacity="0.915"
        fillRule="evenodd"
        stroke="#fff"
        strokeDasharray="none"
        strokeLinecap="butt"
        strokeLinejoin="miter"
        strokeMiterlimit="4"
        strokeOpacity="1"
        strokeWidth="10.01"
        d="M3.769 83.482l217.032 57.606"
      ></path>
      <path
        fill="none"
        fillOpacity="0.915"
        fillRule="evenodd"
        stroke="#fff"
        strokeDasharray="none"
        strokeLinecap="butt"
        strokeLinejoin="miter"
        strokeMiterlimit="4"
        strokeOpacity="1"
        strokeWidth="10.01"
        d="M55.92 15.186l112.73 194.198"
      ></path>
      <path
        fill="none"
        fillOpacity="0.915"
        fillRule="evenodd"
        stroke="#fff"
        strokeDasharray="none"
        strokeLinecap="butt"
        strokeLinejoin="miter"
        strokeMiterlimit="4"
        strokeOpacity="1"
        strokeWidth="10.01"
        d="M32.71 33.083l159.15 158.404"
      ></path>
      <path
        fill="none"
        fillOpacity="0.915"
        fillRule="evenodd"
        stroke="#fff"
        strokeDasharray="none"
        strokeLinecap="butt"
        strokeLinejoin="miter"
        strokeMiterlimit="4"
        strokeOpacity="1"
        strokeWidth="10.01"
        d="M14.922 56.377l194.727 111.816"
      ></path>
      <path
        fill="none"
        fillOpacity="0.915"
        fillRule="evenodd"
        stroke="#fff"
        strokeDasharray="none"
        strokeLinecap="butt"
        strokeLinejoin="miter"
        strokeMiterlimit="4"
        strokeOpacity="1"
        strokeWidth="10.01"
        d="M82.971 3.906L141.6 220.664"
      ></path>
      <path
        fill="none"
        fillOpacity="0.915"
        fillRule="evenodd"
        stroke="#fff"
        strokeDasharray="none"
        strokeLinecap="butt"
        strokeLinejoin="miter"
        strokeMiterlimit="4"
        strokeOpacity="1"
        strokeWidth="10.01"
        d="M112.02.012l.53 224.546"
      ></path>
      <g
        fill="#000"
        ariaLabel="TENSILE"
        style={{ lineHeight: "125%" }}
        fillOpacity="1"
        stroke="#000"
        strokeLinecap="butt"
        strokeLinejoin="miter"
        strokeOpacity="1"
        strokeWidth="0.873"
        fontFamily="Hiragino Kaku Gothic Std"
        fontSize="34.901"
        fontStretch="normal"
        fontStyle="normal"
        fontVariant="normal"
        fontWeight="800"
        letterSpacing="0"
        transform="translate(10.231 11.09)"
        wordSpacing="0"
      >
        <path d="M34.102 92.871h3.943c2.513 0 3.874.07 5.445.245v-6.667c-1.605.175-3.001.245-5.445.245H22.76c-2.13 0-4.049-.105-5.2-.245v6.667c2.477-.21 3.07-.245 5.27-.245h4.118v17.59c0 2.2-.07 3.63-.28 5.27h7.714c-.21-1.64-.28-3.176-.28-5.27z"></path>
        <path d="M68.076 109.45c-1.501.174-3.037.244-5.69.244h-9.004v-5.864h7.539c2.408 0 3.595.07 5.235.244v-6.456c-2.094.21-2.932.244-5.235.244h-7.539v-5.13h9.075c2.338 0 3.594.07 5.2.244v-6.527c-1.64.175-2.827.245-5.2.245H51.323c-2.722 0-3.7-.035-5.13-.105.21 1.71.279 2.932.279 5.096v19.056c0 2.408-.07 3.7-.28 5.095 1.501-.07 2.479-.105 5.131-.105h10.994c3.002 0 4.258.07 5.759.245z"></path>
        <path d="M81.67 91.056c-1.36-2.024-2.129-3.42-2.478-4.362H71.62c.21 1.605.279 3.176.279 5.2v18.358c0 2.583-.07 4.118-.28 5.48h7.435c-.21-1.501-.28-3.037-.28-5.445v-7.818c0-1.71-.07-3.63-.244-5.2.977 1.85 1.57 2.897 2.513 4.293l6.457 9.737c1.186 1.815 1.99 3.106 2.617 4.432h7.644c-.175-1.465-.28-3.07-.28-5.2V92c0-2.269.07-3.735.28-5.305h-7.434c.21 1.605.279 3.001.279 5.305v7.957c0 1.955.07 3.56.21 5.166a46.957 46.957 0 00-2.514-4.154z"></path>
        <path d="M101.216 106.971c.245 1.606.42 2.408.768 3.246.733 1.99 2.025 3.49 3.77 4.467 1.884 1.082 4.816 1.71 7.783 1.71 4.083 0 7.154-1.012 9.179-2.966 1.605-1.57 2.443-3.804 2.443-6.422 0-3.21-1.257-5.55-3.77-7.12-1.326-.837-3.001-1.43-5.654-1.989-4.118-.838-4.118-.838-4.816-1.152-1.117-.523-1.64-1.256-1.64-2.233 0-1.606 1.57-2.758 3.7-2.758 2.652 0 3.908.943 4.327 3.211l7.399-.802c-.454-1.99-.873-3.037-1.745-4.189-1.885-2.617-5.27-3.978-9.703-3.978-6.875 0-11.203 3.385-11.203 8.76 0 2.827 1.187 5.165 3.386 6.7 1.29.908 2.408 1.327 5.619 2.025 3.63.838 4.502 1.082 5.235 1.431.907.419 1.466 1.361 1.466 2.443 0 1.955-1.606 3.141-4.293 3.141-1.78 0-3.246-.593-4.084-1.64-.523-.698-.767-1.326-.907-2.583z"></path>
        <path d="M128.742 86.694c.21 1.535.28 2.548.28 5.165v18.707c0 2.338-.07 3.804-.28 5.165h7.678c-.21-1.465-.279-2.757-.279-5.165V91.86c0-2.548.07-3.734.28-5.165z"></path>
        <path d="M156.515 115.731c2.792 0 4.502.07 5.793.245v-6.701c-1.64.21-2.827.244-5.793.244h-8.446V92.21c0-2.723.034-3.7.279-5.515h-7.609c.21 1.675.28 3.176.28 5.619v18.428c0 2.338-.07 3.874-.28 5.095 1.327-.07 2.757-.105 5.166-.105z"></path>
        <path d="M186.549 109.45c-1.501.174-3.037.244-5.69.244h-9.004v-5.864h7.539c2.408 0 3.595.07 5.235.244v-6.456c-2.094.21-2.932.244-5.235.244h-7.539v-5.13h9.075c2.338 0 3.594.07 5.2.244v-6.527c-1.64.175-2.827.245-5.2.245h-11.134c-2.722 0-3.7-.035-5.13-.105.21 1.71.279 2.932.279 5.096v19.056c0 2.408-.07 3.7-.28 5.095 1.501-.07 2.479-.105 5.131-.105h10.994c3.001 0 4.258.07 5.759.245z"></path>
      </g>
    </svg>
    </div>
  );
}

export default Logo;
