import React from 'react'
import Colors from '../lib/colors'
import { useEffect, useState } from 'react'
import styles from '../lib/Styles'
import config from '../lib/config'
import { BrowserRouter as Router, Switch, Route, Link, useParams} from 'react-router-dom'
import LeftNav from '../components/Nav'
import { colors, Paper } from '@mui/material'
import ContentContainer from '../components/ContentContainer'
import Card from '../components/Card'
import Nav from '../components/Nav'
import { getAgeString } from '../lib/utils'
import UserAction from '../components/UserAction'
import Comments from '../components/Comments'
import { useAuth0 } from '@auth0/auth0-react'

const OneAction = (props) => {

    const [oneAction, setOneAction] = useState([]);
    const { isAuthenticated, getAccessTokenSilently } = useAuth0();
    const { uuid } = useParams();
    let actionUuid = uuid;

    const getOneAction = async () => {
        console.log(config.baseUrl + `/actions/getAction?actionUuid=${actionUuid}`);
        const oneActionData = await fetch(config.baseUrl + `/actions/getAction?actionUuid=${actionUuid}`, 
                                         {credentials: 'include',
                                         headers: {
                                            "Authorization": "Bearer " + (isAuthenticated ? await getAccessTokenSilently() : ""),
                                         },});
        const oneActionJson = await oneActionData.json();
        setOneAction(oneActionJson.status);
        console.log('Got OneAction json:', oneActionJson);
    }
    useEffect(() => {
        getOneAction();
    }, [])

    const renderOneAction = () => {
        return oneAction.map((action, index) => {
            return(
                <div style={{width: '100%'}}>
                    <Card key={action.uuid} style={{paddingBottom: '10px', marginBottom: '10px', width: '46%', minWidth: '400px'}} title={`${action.parentTitle} - ${action.actionTitle}`}>
                        <UserAction userProfilePicture={action.imageUrl} displayName={action.displayName} ageString={getAgeString(action.actionDate)} payload={`${action.actionTitle}`} />
                    </Card>
                    <Comments parentUuid={actionUuid} />
                </div>
            )
        })
    }

    return (
        <div style={{...styles.mainLayout}}>
            <Nav />
            <ContentContainer title={''}>
                <div style={{display: 'flex', flexWrap: 'wrap', flexDirection: 'row', justifyContent: 'flex-start'}}>
                    {oneAction.length > 0 ? renderOneAction() : null}
                </div>
            </ContentContainer>
        </div>
    )
}

export default OneAction