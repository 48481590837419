import React, { useEffect } from 'react'
import { useRecoilState } from 'recoil'
import { authDescriptionState } from '../lib/state'
import config from '../lib/config';
import { itemsCacheState } from '../lib/state';
import { peopleCacheState } from '../lib/state';
import { useAuth0 } from '@auth0/auth0-react';

function ContentHeader(props) {
    const [authDescription, setAuthDescription] = useRecoilState(authDescriptionState);
    const [peopleCache, setPeopleCache] = useRecoilState(peopleCacheState)
    const { isAuthenticated, getAccessTokenSilently } = useAuth0();

    const whoAmI = async () => {
        console.log("Whois with: ");
        console.log("Bearer " + (isAuthenticated ? await getAccessTokenSilently() : ""))
        const whoData = await fetch(config.baseUrl + '/auth/whoami', 
                                        {credentials: 'include',
                                        headers: {
                                            "Authorization": "Bearer " + (isAuthenticated ? await getAccessTokenSilently() : ""),
                                        },
                                        method: 'post'});
        console.log("WhoData: ", whoData);
        const whoJson = await whoData.json();
        console.log("WhoJson: ", whoJson);
        setAuthDescription(whoJson);
    }

    useEffect(() => {
        whoAmI();
    }, []);

    return (
        <div onClick={() => console.log(isAuthenticated)} style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
            <h1>{props.title}</h1>
            <div style={{display: 'flex', alignItems: 'center', flexDirection: 'row'}} onClick={() => {} }>
                <img src={authDescription.status.imageUrl} style={{marginRight: '10px', width: '30px', height: '30px', borderRadius: '50%'}} />
                <h3>{authDescription.status.displayName}</h3>
            </div>
        </div>
    )
}

export default ContentHeader