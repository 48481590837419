import React from 'react'
import Colors from '../lib/colors'
import { useEffect, useState } from 'react'
import styles from '../lib/Styles'
import config from '../lib/config'
import { BrowserRouter as Router, Switch, Route, Link, useParams} from 'react-router-dom'
import Nav from '../components/Nav'
import { Calendar as _Calendar, momentLocalizer } from 'react-big-calendar'
import moment from 'moment'
import "react-big-calendar/lib/css/react-big-calendar.css"
import { colors } from '@mui/material'
import Card from '../components/Card'
import ContentContainer from '../components/ContentContainer'

const Landing = (props) => {

   
    useEffect(() => {
    }, [])

    return (
        <div style={styles.mainLayout}>
            <Nav />
                <ContentContainer title={"Welcome"}>
                    <Card title={"Recent activity"}>
                    <span>Some things.</span>
                    </Card>
                </ContentContainer>
        </div>
    )
}

export default Landing
