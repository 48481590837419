import React from 'react'
import Colors from '../lib/colors'
import { useEffect, useState } from 'react'

const Card = (props) => {
    const title = props.title || ""

    const onClick = () => {
        if (props.onClick) {
            props.onClick()
        } else {
            console.log("No onClick function defined for this card")
        }
    }   

    const renderTitle = () => {
        if (props.title) {
            return (
                <h1 style={{paddingTop: '0px', marginTop: '0px'}}>{title}</h1>
            )
        }
    }
    
    return (
        <div 
            onClick={onClick}
            style={{
                borderRadius: 5,
                backgroundColor: '#fff',
                padding: 20,
                boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px',
                ...props.style
            }}>
            {renderTitle()}
            {props.children}

        </div>
    )
}

export default Card