import {
    RecoilRoot,
    atom,
    selector,
    useRecoilState,
    useRecoilValue,
  } from 'recoil';

export const loggedInState = atom({
    key: 'loggedInState',
    default: false,
})

export const gotAuthState = atom({
    key: 'gotAuthState',
    default: false,
})

export const newListItemTitleState = atom({
    key: 'newListItemTitleState',
    default: "",
})

export const listsCacheState = atom({
    key: 'listsCacheState',
    default: {}
})

export const itemsCacheState = atom({
    key: 'itemsCacheState',
    default: {}
})

export const thingsCacheState = atom({
    key: 'thingsCacheState',
    default: []
})

export const attributesCacheState = atom({
    key: 'attributesCacheState',
    default: {}
})

export const newListTitleState = atom({
    key: 'newListTitleState',
    default: "",
})

export const peopleCacheState = atom({
    key: 'peopleCacheState',
    default: {}
})

export const tagsCacheState = atom({
    key: 'tagsCacheState',
    default: {}
})

export const auth0TokenState = atom({
    key: 'auth0TokenState',
    default: ""
})

export const listsState = atom({
    key: 'listsState',
    default: {
        "success": true,
        "status": 
            [
                {
                    "listUuid": "",
                    "listItems": [],
                    "title": "",
                    "userUuid": "",
                    "displayName": "",
                }
            ]
    }
})

export const authDescriptionState = atom({
    key: 'authDescriptionState',
    default: {    
        "success": false,
        "status": {
            "displayName": "",
            "sessionId": "",
            "userUuid": "",
            "email": "",
            "orgId": "",
            "errors": "",
            "imageUrl": ""
        }
    }
})
