import React from 'react'
import Colors from '../lib/colors'
import { useEffect, useState } from 'react'
import styles from '../lib/Styles'
import config from '../lib/config'
import { BrowserRouter as Router, Switch, Route, Link, useParams} from 'react-router-dom'
import LeftNav from '../components/Nav'
import { colors, Paper } from '@mui/material'
import ContentContainer from '../components/ContentContainer'
import Card from '../components/Card'
import Nav from '../components/Nav'
import { peopleCacheState } from '../lib/state'
import { useRecoilState } from 'recoil'
import { useNavigate } from 'react-router-dom'
import AttributesPreview from '../components/AttributesPreview'
import TagsPreview from '../components/TagsPreview'
import AddNewPerson from '../components/AddNewPerson'
import { useAuth0 } from '@auth0/auth0-react'

const People = (props) => {

    const { isAuthenticated, getAccessTokenSilently } = useAuth0();
    const [peopleCache, setPeopleCache] = useRecoilState(peopleCacheState)
    const [people, setPeople] = useState(() => {
        let _people = peopleCache;
        if (typeof _people != 'undefined') {
            let _peopleArray = [];
            let _peopleKeys = Object.values(_people);
            _peopleKeys.map((person) => {
                _peopleArray.push(person);
            });
            console.log("People Array:", _peopleArray)
            return _peopleArray;
        } else {
            return [];
        }
    });

    let Navigate = useNavigate();

    const getPeople = async () => {
        const peopleData = await fetch(config.baseUrl + `/contacts/getContacts`, 
                                      {credentials: 'include',
                                      headers: {
                                        "Authorization": "Bearer " + (isAuthenticated ? await getAccessTokenSilently() : ""),
                                      },});
        const peopleJson = await peopleData.json();
        if (peopleJson.success === true) {
            setPeople(peopleJson.status);
            let _peopleCache = {...peopleCache};
            peopleJson.status.map((person) => {
                _peopleCache[person.contactUuid] = person;
            })
            setPeopleCache(_peopleCache);
        }
        console.log('Got People json:', peopleJson);
    }
    useEffect(() => {
        getPeople();
    }, [])

    const renderPeople = () => {
        return people.map((person, index) => {
            if ((person.firstName === undefined) || (person.lastName === undefined) || (person.companyName === undefined)) {
                return null;
            }
            let name = `${person.firstName} ${person.lastName}`;
            if (person.firstName === '') {
                name = person.companyName;
            }
            return(
                <Card onClick={() => Navigate(`/onePerson/${person.contactUuid}`)} key={person.contactUuid} style={{marginRight: '10px', marginBottom: '10px', width: "295px"}} title={name}>
                    <div style={{display: 'flex', flexDirection: 'column'}}>
                        <AttributesPreview parentUuid={person.contactUuid} />
                        <TagsPreview parentUuid={person.contactUuid} />
                    </div>
                </Card>
            )
        })
    }

    return (
        <div style={{...styles.mainLayout}}>
            <Nav />
            <ContentContainer title={'Contacts'}>
                <div style={{display: 'flex', flexWrap: 'wrap', flexDirection: 'row', justifyContent: 'flex-start'}}>
                    <AddNewPerson successFunction={getPeople} style={{marginRight: '10px', marginBottom: '10px', width: "295px"}} />
                    {people.length > 0 ? renderPeople() : null}
                </div>
            </ContentContainer>
        </div>
    )
}

export default People