import React from 'react'
import Colors from '../lib/colors'
import { useEffect, useState } from 'react'
import styles from '../lib/Styles'
import config from '../lib/config'
import { BrowserRouter as Router, Switch, Route, Link, useParams} from 'react-router-dom'
import LeftNav from '../components/Nav'
import { colors, Paper, Checkbox, createTheme, ThemeProvider } from '@mui/material'
import ContentContainer from '../components/ContentContainer'
import Card from '../components/Card'
import Nav from '../components/Nav'
import { useRecoilState } from 'recoil'
import { listsCacheState } from '../lib/state'
import AddToList from '../components/AddToList'
import { newListItemTitleState } from '../lib/state'
import _ from 'lodash';
import { useNavigate } from 'react-router-dom'
import { useAuth0 } from '@auth0/auth0-react'

const OneList = (props) => {

    let { uuid } = useParams();
    const { isAuthenticated, getAccessTokenSilently } = useAuth0();

    let Navigate = useNavigate();

    const theme = createTheme({
        components: {
            MuiCheckbox: {
              styleOverrides: {
                root: {
                        // Apply this color when the checkbox is checked
                        '&.Mui-checked': {
                            color: 'whitesmoke',
                        }
                    }
                }
            }
        }
    })

    const [OneList, setOneList] = useState([]);
    const [listTitle, setListTitle] = useState('');
    const [listsCache, setListsCache] = useRecoilState(listsCacheState);
    const [newListItemTitle, setNewListItemTitle] = useRecoilState(newListItemTitleState);

    const getOneList = async () => {
        console.log("Getting list with token: ", isAuthenticated ? await getAccessTokenSilently() : "");
        const OneListData = await fetch(config.baseUrl + `/toDoLists/getToDoList?listUuid=${uuid}`, 
                                       {credentials: 'include',
                                        headers: {
                                            "Authorization": "Bearer " + (isAuthenticated ? await getAccessTokenSilently() : ""),
                                            },
                                        });
        const OneListJson = await OneListData.json();
        if (OneListJson.success === true) {
            let items = OneListJson.status[0].listItems;
            items.sort((a, b) => a.complete - b.complete);
            OneListJson.status[0].listItems = items;
            setOneList(OneListJson.status);
            setListTitle(OneListJson.status[0].title);
            let _listsCache = {...listsCache};
            _listsCache[uuid] = OneListJson.status;
            setListsCache(_listsCache);
        }
    }

    let completeItem = async(item) => {
        let completeData;
        let completeJson;
        console.log("I'mma try and comlete the item now", item);
        if (item.complete == false) {
            completeData = await fetch(config.baseUrl + '/toDoLists/completeToDoListItem?itemUuid=' + item.itemUuid, 
                                      {credentials: 'include',
                                      headers: {
                                        "Authorization": "Bearer " + (isAuthenticated ? await getAccessTokenSilently() : ""),
                                      },});
        }
        if (item.complete == true) {
            completeData = await fetch(config.baseUrl + '/toDoLists/uncompleteToDoListItem?itemUuid=' + item.itemUuid, 
                                      {credentials: 'include',
                                      headers: {
                                        "Authorization": "Bearer " + (isAuthenticated ? await getAccessTokenSilently() : ""),
                                      },});
        }
        completeJson = await completeData.json();
        if (completeJson.success == true) {
            getOneList();
        }
    }

    const checkItem = (item) => {
        let index = listsCache[uuid][0].listItems.findIndex(listItem => listItem.itemUuid == item.itemUuid);
        let _listsCache = _.cloneDeep(listsCache);
        _listsCache[uuid][0].listItems[index].complete = true;
        setListsCache(_listsCache);
        console.log(_listsCache[uuid][0].listItems[index]);
    }

    const renderListItem = (listItem, index) => { 
        let backgroundColor = index % 2 == 0 ? 'whitesmoke' : 'white';
        if (listItem.complete) {
            backgroundColor = 'lightgreen';
        }
        let isChecked = listItem.complete;
        return(
            <div style={{padding: 7, backgroundColor: backgroundColor}}>
                <ThemeProvider theme={theme}>
                    
                    <Checkbox onChange={() => {completeItem(listItem); checkItem(listItem)}} checked={listItem.complete} style={{}} />
                </ThemeProvider>
                <span onClick={() => Navigate(`/listItem/${listItem.itemUuid}`)}>{listItem.itemTitle}</span>
            </div>
        )
    }

    const renderOneList = () => {
        console.log('Rendering list:', listsCache[uuid]);
        let filterWord = newListItemTitle;
        filterWord = filterWord == undefined ? "" : filterWord.toLowerCase();
        let filteredList = listsCache[uuid][0].listItems.filter((item) => {
            return item.itemTitle.toLowerCase().includes(filterWord);
        });
        return(
            <Card style={{width: '100%'}} title={listsCache[uuid][0].title}>
                <div><AddToList listUuid={uuid} successFunction={() => {setNewListItemTitle(""); getOneList()}} /></div>
                {filteredList.map((item, index) => {
                    return(
                        renderListItem(item, index)
                    )
                })}
            </Card>
        )
    }

    useEffect(() => {
        getOneList();
    }, [uuid]);

    useEffect(() => {
        getOneList();
    }, [isAuthenticated]);

    return (
        <div style={{...styles.mainLayout}}>
            <Nav />
            <ContentContainer>
                <div style={{display: 'flex', flexWrap: 'wrap', flexDirection: 'row', justifyContent: 'flex-start'}}>
                    {typeof listsCache[uuid] !== 'undefined' ? renderOneList() : null}
                </div>
            </ContentContainer>
        </div>
    )
}

export default OneList