import React from 'react'
import Colors from '../lib/colors'
import { useEffect, useState } from 'react'
import styles from '../lib/Styles'
import config from '../lib/config'
import { BrowserRouter as Router, Switch, Route, Link, useParams} from 'react-router-dom'
import LeftNav from '../components/Nav'
import { colors, Paper } from '@mui/material'
import ContentContainer from '../components/ContentContainer'
import Card from '../components/Card'
import { thingsCacheState } from '../lib/state'
import Nav from '../components/Nav'
import { useRecoilState } from 'recoil'
import Comments from '../components/Comments'
import Attributes from '../components/Attributes'
import NewAttribute from '../components/NewAttribute'
import Tags from '../components/Tags'
import { peopleCacheState } from '../lib/state'
import Actions from '../components/Actions'
import { useAuth0 } from '@auth0/auth0-react'

const OnePerson = (props) => {

    let {uuid } = useParams();

    const [peopleCache, setPeopleCache] = useRecoilState(peopleCacheState)
    const {isAuthenticated, getAccessTokenSilently } = useAuth0();
    const [onePerson, setOnePerson] = useState(() => {
        if (uuid in peopleCache) {
            return [peopleCache[uuid]];
        } else {
            return [];
        }
    });

    const getOnePerson = async () => {
        if (isAuthenticated) {
            console.log("I am going to get the person with token: ", await getAccessTokenSilently());
            const onePersonData = await fetch(config.baseUrl + `/contacts/getContact?contactUuid=${uuid}`, 
                                            {credentials: 'include',
                                            headers: {
                                                "Authorization": "Bearer " + (isAuthenticated ? await getAccessTokenSilently() : ""),
                                            },
                                        });
            const onePersonJson = await onePersonData.json();
            if (onePersonJson.success === true) {
                setOnePerson(onePersonJson.status);
            }
            console.log('Got onePerson json:', onePersonJson);
        }
    }
    useEffect(() => {
        getOnePerson();
    }, [])

    useEffect(() => {
        getOnePerson();
    }, [isAuthenticated])

    const renderOnePerson = () => {
        return onePerson.map((person, index) => {
            if ((person.firstName === undefined) || (person.lastName === undefined) || (person.companyName === undefined)) {
                return null;
            }
            let name = `${person.firstName} ${person.lastName}`;
            if (person.firstName === '') {
                name = person.companyName;
            }
            return(
                <Card onClick={() => console.log(person)} key={person.uuid} style={{marginRight: '10px', marginBottom: '10px', width: "46%", minWidth: '400px'}} title={name}>
                    <div style={{display: 'flex', flexDirection: 'column'}}>
                        {person.companyName !== '' ? <h4>{person.companyName}</h4> : null}
                        <Attributes parentUuid={uuid} />
                    </div>
                </Card>
            )
        })
    }

    return (
        <div style={{...styles.mainLayout}}>
            <Nav />
            <ContentContainer title={''}>
                <div style={{display: 'flex', flexWrap: 'wrap', flexDirection: 'row', justifyContent: 'space-between', justifyItems: 'top'}}>
                    {onePerson.length > 0 ? renderOnePerson() : null}
                    <Tags parentUuid={uuid} />
                    <Comments parentUuid={uuid} />                    
                    <Actions parentUuid={uuid} />
                </div>
            </ContentContainer>
        </div>
    )
}

export default OnePerson