import React from 'react'
import Colors from '../lib/colors'

function Tbutton(props) {

    let divStyle = {
        backgroundColor: Colors.tensileYellow,
        padding: '5px',
        width: 'auto',
        height: 'auto',
        display: 'inline-block',
        color: 'white',
    }

    return (
        <div onClick={() => {props.onClick()}} style={{...divStyle,...props.style}}>
            {props.title}
        </div>
    )
}

export default Tbutton