import React from 'react'
import Colors from '../lib/colors'
import { useEffect, useState } from 'react'
import styles from '../lib/Styles'
import config from '../lib/config'
import { BrowserRouter as Router, Switch, Route, Link, useParams} from 'react-router-dom'
import LeftNav from '../components/Nav'
import { colors, Paper } from '@mui/material'
import ContentContainer from '../components/ContentContainer'
import Card from '../components/Card'
import { thingsCacheState } from '../lib/state'
import Nav from '../components/Nav'
import { useRecoilState } from 'recoil'
import Comments from '../components/Comments'
import Attributes from '../components/Attributes'
import NewAttribute from '../components/NewAttribute'
import Tags from '../components/Tags'
import Actions from '../components/Actions'
import { useAuth0 } from '@auth0/auth0-react'

const OneThing = (props) => {

    let {uuid } = useParams();

    const [thingsCache, setThingsCache] = useRecoilState(thingsCacheState)
    const { isAuthenticated, getAccessTokenSilently } = useAuth0();
    const [oneThing, setOneThing] = useState(() => {
        let _oneThing = thingsCache.find((thing) => thing.uuid === uuid);
        if (typeof _oneThing !== 'undefined') {
            return _oneThing;
        }
        return [];
    
    });

    const getOneThing = async () => {
        const oneThingData = await fetch(config.baseUrl + `/things/getThing?uuid=${uuid}`, 
                                        {credentials: 'include',
                                        headers: {
                                            "Authorization": "Bearer " + (isAuthenticated ? await getAccessTokenSilently() : ""),
                                        },
                                    });
        const oneThingJson = await oneThingData.json();
        setOneThing(oneThingJson.status);
        console.log('Got OneThing json:', oneThingJson);
    }
    useEffect(() => {
        getOneThing();
    }, [])

    const renderOneThing = () => {
        return oneThing.map((thing, index) => {
            return(
                <Card style={{width: '46%', marginRight: '10px', marginBottom: '10px', minWidth: '400px'}} title={thing.title} key={thing.uuid}>
                    <Attributes parentUuid={uuid} />
                </Card>
            )
        })
    }

    return (
        <div style={{...styles.mainLayout}}>
            <Nav />
            <ContentContainer title={''}>
                <div style={{display: 'flex', flexWrap: 'wrap', flexDirection: 'row', justifyContent: 'space-between', justifyItems: 'top'}}>
                    {oneThing.length > 0 ? renderOneThing() : null}
                    <Tags parentUuid={uuid} />
                    <Comments parentUuid={uuid} />
                    <Actions parentUuid={uuid} />
                </div>
            </ContentContainer>
        </div>
    )
}

export default OneThing