import React from 'react'
import { listsCacheState } from './state'
import { useRecoilState } from 'recoil'
import config from './config'

export const GetLists = async () => {

    const [listsCache, setListsCache] = useRecoilState(listsCacheState)


    const listsData = await fetch(`${config.baseUrl}/toDoLists/all`, {credentials: 'include'})
    const _lists = await listsData.json()
    setListsCache(_lists.status)
    let _listsCache = {...listsCache};
    _lists.status.map((list) => {
        _listsCache[list.listUuid] = [list];
    })
    setListsCache(_listsCache);
}

export const getAgeString = (creationDate) => {
    let now = new Date();
    let creationDateString = '';
    creationDate = new Date(creationDate);
    let age = now - creationDate;
    let ageInSeconds = age / 1000;
    let ageInMinutes = ageInSeconds / 60;
    let ageInHours = ageInMinutes / 60;
    let ageInDays = ageInHours / 24;
    let ageInYears = ageInDays / 365;
    if (ageInYears >= 1) {
        creationDateString = Math.floor(ageInYears) + ' years ago';
    }
    if (ageInDays >= 1 && ageInYears < 1) {
        creationDateString = Math.floor(ageInDays) + ' days ago';
    }
    if (ageInHours >= 1 && ageInDays < 1) {
        creationDateString = Math.floor(ageInHours) + ' hours ago';
    }
    if (ageInMinutes >= 1 && ageInHours < 1) {
        creationDateString = Math.floor(ageInMinutes) + ' minutes ago';
    }
    if (ageInSeconds >= 1 && ageInMinutes < 1) {
        creationDateString = Math.floor(ageInSeconds) + ' seconds ago';
    }
    return creationDateString;
}